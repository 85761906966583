export const getDefaultState = () => ({
  session: {
    permissions: {},
    personID: null,
    relationID: null,
    rightGroup: null,
    username: null,
  },
  region: {},
  loadingRegion: false,
  monthlyContinuingFees: [],
  monthlyPromotionAndDevelopmentFees: [],
  subregionTagID: '',
  subregionsEnabled: false,
  currencyReporting: {},
  currencyRfa: {},
  franchiseAgreement: { franchiseAgreementSegments: [] },
  currentDevelopmentScheduleSnapshot: { developmentSchedules: [] },
  currentPerformanceScheduleSnapshot: { performanceSchedules: [] },
  subRegionInfo: null,
  subregionTags: [],
  subregionDropdown: [],
  managingDirectors: [],
  loadingManagingCompany: false,
  currentManagingCompanySnapshot: null,
  managingCompaniesSnapshots: {},
  managingCompanySnapshot: [],
  regionalPersonFilters: { only_active: '1' },
  regionalPerson: [],
  feeRelief: [],
  loadingFeeRelief: false,
  feeReliefFilters: {
    only_active: '1',
    sort: '-fromDate',
  },
  feeReliefTypes: [
    { value: '0', label: 'Fee Relief' },
    { value: '1', label: 'Regional Initiative' },
    { value: '2', label: 'Kickback' },
    { value: '3', label: 'Subsidy' },
  ],
  affectTypeOfFees: [
    { value: '0', label: 'Local Franchise Agreement' },
    { value: '1', label: 'Regional Fees' },
  ],
  currencyPayment: {},
  currencyLfa: {},
  developmentSchedule: [],
  performanceSchedule: [],
  businessPlanYear: null,
  businessPlanYears: [],
  businessPlanLoading: false,
  notes: {
    list: [],
    totalRows: 0,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 35,
  },
  history: {
    list: [],
    totalRows: 0,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 35,
  },
  businessPlan: [],
  businessPlanV2: {},
  countries: [],
  nextSubcountryIDs: [],
  phonecodes: [],
  countryStates: {},
  loadingCountryStates: false,
  emailCommunicationTags: [],
  currencyDropdown: [],
  franchiseAgreementSegmentTypes: [],
  notesCategories: [],
  companies: [],
  devSnapshotList: [],
  todaysDevSchedule: {
    snapshotID: '-1',
    list: [],
    loading: false,
  },
  devSchedule: {
    snapshotID: null,
    list: [],
    loading: false,
  },
  perfSnapshotList: [],
  perfSchedule: {
    snapshotID: null,
    list: [],
    loading: false,
  },
  regionRoles: [],
  personalCurrency: {
    isEnabled: false,
    currency: 'EUR',
  },
});
