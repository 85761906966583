import { RegionEditorService } from '@/services/modules/regionEditor.service';
const isValid = (res) => res && !res.error;

export async function promiseAll(promises: Promise<any>[] = []) {
  return await Promise.all(promises.map(p => new Promise<any>(resolve => (p && p.then) ? p.then(resolve).catch(e => resolve({ error: e })) : resolve(null))));
}

export async function getManagingCompanyBySnapshotID({ commit, state }, _params: any = {}) {
  const { refresh, ...params } = _params;
  try {
    const managingCompanySnapshotID = params.snapshotID || (refresh ? null : state.currentManagingCompanySnapshot);
    const managingCompanyID = params.companyID || (refresh ? null : state.managingCompaniesSnapshots[managingCompanySnapshotID]?.company?.companyID);
    let companyShareholderSnapshotID = params.companyShareholderSnapshotID;
    let companyShareholders = [];
    let snapshots = [];
    let company = null;
    
    if (managingCompanySnapshotID) {
      const [snapshotsRes, companyRes] = await Promise.all<any>([
        RegionEditorService.companySnapshots(managingCompanyID),
        RegionEditorService.managingCompanyBySnapshotID(managingCompanySnapshotID)
      ].map(p => new Promise((resolve) => {
        p.then((res) => resolve(res)).catch((e) => resolve({ error: e }));
      })));
      if (isValid(snapshotsRes)) {
        snapshots = snapshotsRes?.data?.result || [];
        companyShareholderSnapshotID = companyShareholderSnapshotID || snapshots[0]?.ID;        
        if (companyShareholderSnapshotID) {
          try {
            const companyShareholderSnapshotsRes = await RegionEditorService.companyShareholderSnapshot(companyShareholderSnapshotID);
            companyShareholders = companyShareholderSnapshotsRes?.data?.result?.companyShareholders || [];
          } catch(e) { /**/ }
        }
      } 
      company = companyRes?.data || null;     
    }
    commit('setCurrentManagingCompanySnapshotID', managingCompanySnapshotID);
    commit('setManagingCompaniesSnapshots', {
      snapshotID: managingCompanySnapshotID,
      company,
      snapshots,
      companyShareholderSnapshotID,
      companyShareholders,
    });
  } catch(e) { /**/ }
}

export async function getRegion(store, params: any = {}) {
  const { commit, state } = store;
  const subregionTagID = params.subregionTagID || state.subregionTagID;
  const region_id = params.region_id || state?.region?.ID;
  commit('setSubregionTagID', subregionTagID);
  const res: any[] = await Promise.all([
    RegionEditorService.getRegion(region_id, { subregionTagID }),
    RegionEditorService.franchiseAgreement(region_id, { subregionTagID }),
    RegionEditorService.managingCompanySnapshot(region_id, { ...(!subregionTagID ? {} : { 'meta[subregionTagID]': subregionTagID }), }),
	RegionEditorService.get('/v1/region/getInfo', { regionID: region_id }),
	...(!subregionTagID ? [] : [RegionEditorService.get('/v1/subRegion/getInfo', { regionID: region_id, subRegionID: subregionTagID }),]),
  ].map(p => new Promise((resolve) => {
    p.then((res) => resolve(res)).catch((e) => resolve({ error: e }));
  }))); 
  
  const [
    getRegion,
    franchiseAgreement,
    managingCompanySnapshot,
	regionInfo, 
	subRegionInfo,
  ] = res;

  commit('setSubRegionInfo', subRegionInfo?.data?.data || null);

  if (isValid(getRegion)) {
    const region = getRegion?.data?.result;
    try {
      commit('setRegion', region);
      if (region) {
        const states = await RegionEditorService.countryStates(region.countryISO);
        commit('setCountryStates', { [region.countryISO]: states?.res?.result || [] });
      }
    } catch (e) { /**/ }
  }

//   if (isValid(franchiseAgreement)) commit('setFranchiseAgreement', franchiseAgreement?.data?.result);
//   if (isValid(franchiseAgreement)) commit('setFranchiseAgreement', { ...franchiseAgreement?.data?.result, franchiseFeeStructures: franchiseAgreement?.data?.result?.franchiseAgreementSegments });
  if (isValid(regionInfo) || isValid(subRegionInfo)) {
	commit('setFranchiseAgreement', subRegionInfo?.data?.data?.franchiseAgreement || regionInfo?.data?.data?.regionalFranchiseAgreement);
  }

  if (isValid(managingCompanySnapshot)) {
    try {
      const managingCompanySnapshots = managingCompanySnapshot?.data?.result || [];
      commit('setManagingCompanySnapshot', managingCompanySnapshots);      
      await getManagingCompanyBySnapshotID(store, {
        refresh: true,
        companyID: managingCompanySnapshots[0]?.companyID,
        snapshotID: managingCompanySnapshots[0]?.ID,
      });
    } catch (e) { /**/ }
  }

  return res;
}

export async function init(store, region_id) {
  try {
    const { commit, state } = store;

    // get selectedYear from local storage
    let selectedYear = Number(localStorage.getItem('selectedYear'));
    // if selectedYear = 0 (no value selected in the datepicker), then set the selectedYear to the current year
    if (selectedYear === 0) {
      selectedYear = new Date().getFullYear();
    }
    
    const promises = [
      RegionEditorService.sessionPermissions(),
      RegionEditorService.countries(),
      RegionEditorService.nextSubcountryIDs(),
      RegionEditorService.phonecodes(),
      RegionEditorService.emailCommunicationTags(),
      RegionEditorService.currencyDropdown(),
      RegionEditorService.franchiseAgreementSegmentTypes(),
      RegionEditorService.notesCategories(),
      // RegionEditorService.companyShareholderSnapshot(),
    ];

    if (region_id) {
      let subregionTagID = '';
      let businessPlanYears = [];
      try {
        let subregionsEnabled = false;
        let subregionDropdown = [];

        const [subregionsEnabledRes, businessPlanYearsRes]: any[] = await Promise.all(
          [RegionEditorService.subregionsEnabled(region_id), RegionEditorService.getBusinessPlanYearsList(region_id)].map(
            (p) =>
              new Promise((resolve) => {
                p.then((res) => resolve(res)).catch((e) => resolve({ error: e }));
              })
          )
        );

        subregionsEnabled = subregionsEnabledRes?.data?.result?.subregionsEnabled || false;
        businessPlanYears = businessPlanYearsRes?.data?.data || [];

        if (subregionsEnabled) {
          const subregionDropdownRes = await RegionEditorService.subregionDropdown(region_id);
          subregionDropdown = subregionDropdownRes?.data?.result || [];
          subregionTagID = subregionDropdown[0]?.entryKey;
        }

        commit('setSubregionsEnabled', subregionsEnabled);
        commit('setSubregionDropdown', subregionDropdown);
      } catch (e) {
        /**/
      }
      commit('setSubregionTagID', subregionTagID);
      commit('setBusinessPlanYears', businessPlanYears);
      commit('setBusinessPlanYear', selectedYear);

      promises.push(getRegion(store, { region_id, subregionTagID }));
      promises.push(RegionEditorService.currencyReporting(region_id));
      promises.push(RegionEditorService.currencyRfa(region_id));
      promises.push(RegionEditorService.currentDevelopmentScheduleSnapshot(region_id));
      promises.push(RegionEditorService.currentPerformanceScheduleSnapshot(region_id));
      promises.push(RegionEditorService.subregionTags(region_id));
      promises.push(RegionEditorService.managingDirectors(region_id));
      promises.push(RegionEditorService.regionalPerson(region_id));
      promises.push(RegionEditorService.feeRelief(region_id));
      promises.push(RegionEditorService.currencyPayment(region_id));
      promises.push(RegionEditorService.currencyLfa(region_id));
      promises.push(RegionEditorService.developmentSchedule(region_id));
      promises.push(RegionEditorService.performanceSchedule(region_id));
      promises.push(RegionEditorService.getNotes(region_id));
      promises.push(RegionEditorService.history(region_id));
      promises.push(
        RegionEditorService.businessPlan(region_id, {
          sort: 'month',
          'textFilter[year]': selectedYear,
        })
      );
      // get the businessplan, but now instead of the current year, get it from the datepicker in the summary dashboard
      promises.push(RegionEditorService.businessPlanV2(region_id, selectedYear));
      promises.push(RegionEditorService.getDevSnapShotList(region_id));
      promises.push(RegionEditorService.getPerformanceSnapShotList(region_id));
      promises.push(RegionEditorService.getRolesForRegionDropDown());
    }

    const res: any[] = await Promise.all(promises.map(p => new Promise((resolve) => {
      p.then((res) => resolve(res)).catch((e) => resolve({ error: e }));
    })));

    const [
      session,
      countries,
      nextSubcountryIDs,
      phonecodes,
      emailCommunicationTags,
      currencyDropdown,
      franchiseAgreementSegmentTypes,
      notesCategories,
      getRegionRes,
      currencyReporting,
      currencyRfa,
      currentDevelopmentScheduleSnapshot,
      currentPerformanceScheduleSnapshot,
      subregionTags,
      managingDirectors,
      regionalPerson,
      feeRelief,
      currencyPayment,
      currencyLfa,
      developmentSchedule,
      performanceSchedule,
      note,
      history,
      businessPlan,
      businessPlanV2,
      devSnapShotList,
      performanceShotList,
      regionRoles,
    ] = res;

    if (isValid(session)) commit('setState', { session: { ...state.session, ...session?.data }, });
    if (isValid(countries)) commit('setCountries', countries?.data?.result);
    if (isValid(nextSubcountryIDs)) commit('setNextSubcountryIDs', nextSubcountryIDs?.data?.result);
    if (isValid(phonecodes)) commit('setPhonecodes', phonecodes?.data?.result);
    if (isValid(emailCommunicationTags)) commit('setEmailCommunicationTags', emailCommunicationTags?.data?.result);
    if (isValid(currencyDropdown)) commit('setCurrencyDropdown', currencyDropdown?.data?.result);
    if (isValid(franchiseAgreementSegmentTypes)) commit('setFranchiseAgreementSegmentTypes', franchiseAgreementSegmentTypes?.data?.result);
    if (isValid(notesCategories)) commit('setNotesCategories', notesCategories?.data?.result);
    // if (isValid(getRegionRes)) commit('setRegion', getRegionRes?.data?.result);
    if (isValid(currencyReporting)) commit('setCurrencyReporting', currencyReporting?.data?.result);
    if (isValid(currencyRfa)) commit('setCurrencyRfa', currencyRfa?.data?.result);

    //here the data is comitted to the store, and is now available in all components via the store
    commit('setBusinessPlanV2', businessPlanV2);

    const devSnapshots = devSnapShotList?.data?.result || [];
    commit('setDevSnapshotList', devSnapshots);
    commit('setCurrentDevelopmentScheduleSnapshot', { ...state.currentDevelopmentScheduleSnapshot, ...devSnapshots[0] });

    if (isValid(currentDevelopmentScheduleSnapshot)) {
      try {
        const curDevSchSnapID = currentDevelopmentScheduleSnapshot?.data?.result?.ID;
        commit('setCurrentDevelopmentScheduleSnapshot', currentDevelopmentScheduleSnapshot?.data?.result);
        if (curDevSchSnapID) {
          try {
            const devSchedule: any = await RegionEditorService.getDevelopmentScheduleBySnapshot(curDevSchSnapID);
            const devScheduleList = devSchedule?.data?.result?.developmentSchedules || [];
            commit('setState', { 
              devSchedule: { snapshotID: curDevSchSnapID || null, list: devScheduleList, }, 
              todaysDevSchedule: {
                ...state.todaysDevSchedule,
                // list: devScheduleList.map((s, i) => ({
                //   // ID: null,
                //   year: Math.max(...devScheduleList.map(s => s.year)) + (i + 1),
                //   planNumFranchises: "",
                //   planNumAssociates: "",
                //   planGCI: "",
                //   actualNumFranchises: 0,
                //   actualNumAssociates: 0,
                //   actualGCI: 0,
                // })),
                list: (() => {
                  const list = [];
                  if (!devScheduleList.length) return list;
                  for (let i = 0; i < 20; i++) {
                    list.push({
                      // ID: null,
                      year: Math.max(...devScheduleList.map(s => s.year)) + (i + 1),
                      planNumFranchises: "",
                      planNumAssociates: "",
                      planGCI: "",
                      actualNumFranchises: 0,
                      actualNumAssociates: 0,
                      actualGCI: 0,
                    });
                  }
                  return list;
                })(),
              }
            });
          } catch(e) { /**/ }    
        }
      } catch(e) { /**/ }
    }

    const perfSnapshots = performanceShotList?.data?.result || [];
    commit('setPerfSnapshotList', perfSnapshots);
    commit('setCurrentPerformanceScheduleSnapshot', { ...state.currentPerformanceScheduleSnapshot, ...perfSnapshots[0] });  
    
    if (isValid(currentPerformanceScheduleSnapshot)) {
      try {
        const curPerfSchSnapID = currentPerformanceScheduleSnapshot?.data?.result?.ID;
        if (curPerfSchSnapID) {
          try {            
            const Schedule: any = await RegionEditorService.getPerformanceScheduleBySnapshot(curPerfSchSnapID);
            commit('setPerfSchedule', {
              snapshotID: curPerfSchSnapID,
              list: Schedule?.data?.result?.performanceSchedules || [],
            });
          } catch(e) { /**/ }  
        }
      } catch(e) { /**/ }      
    }

    if (isValid(subregionTags)) commit('setSubregionTags', subregionTags?.data?.result);
    if (isValid(managingDirectors)) commit('setManagingDirectors', managingDirectors?.data?.result);
    if (isValid(regionalPerson)) commit('setRegionalPerson', regionalPerson?.data?.result);
    if (isValid(feeRelief)) commit('setFeeRelief', feeRelief?.data?.result);
    if (isValid(currencyPayment)) commit('setCurrencyPayment', currencyPayment?.data?.result);
    if (isValid(currencyLfa)) commit('setCurrencyLfa', currencyLfa?.data?.result);
    if (isValid(developmentSchedule)) commit('setDevelopmentSchedule', developmentSchedule?.data?.result);
    if (isValid(performanceSchedule)) commit('setPerformanceSchedule', performanceSchedule?.data?.result);
    // if (isValid(devSnapShotList)) commit('setDevSnapShotList', devSnapShotList?.data?.result);
    // if (isValid(performanceShotList)) commit('setPerformanceShotList', performanceShotList?.data?.result);
    
    if (isValid(note)) {
      const totalNotesRows = Number(note?.data?.info?.numRows || '0');
      const totalPages = Math.ceil(totalNotesRows / state.notes.rowsPerPage);
      commit('setNotes', {
        list: note?.data?.result,
        totalRows: totalNotesRows,
        totalPages,
        currentPage: 1,
      });
    }
    if (isValid(history)) {
      const totalHistoryRows = Number(history?.data?.info?.numRows || '0');
      const totalPages = Math.ceil(totalHistoryRows / state.history.rowsPerPage);
      commit('setHistory', {
        list: history?.data?.result,
        totalRows: totalHistoryRows,
        totalPages,
        currentPage: 1,
      });
    }
    if (isValid(businessPlan)) commit('setBusinessPlan', businessPlan?.data?.result);
    if (isValid(regionRoles)) commit('setRegionRoles', regionRoles?.data?.result);
    if (isValid(getRegionRes)) {
      const ISO = getRegionRes[0]?.data?.result.countryISO;
      const res: any = await RegionEditorService.countryStates(ISO);
      commit('setCountryStates', { [ISO]: res?.data?.result || [] });
    }
    
    return res;
  } catch(e) { throw e; }
}
